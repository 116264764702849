<template>
  <div id="preisliste" class="container-fluid bg-primary">
    <div class="container-xxl">
      <h2 class="text-white">{{ $t("pricing.title") }}</h2>
      <p class="text-white" v-html="$t('pricing.intro')"></p>
      <table class="table table-sm table-striped">
        <tbody>
          <tr v-for="treatment in treatments" :key="treatment">
            <td>
              <h6>{{ $t("pricing.treatments." + treatment + ".label") }}</h6>
            </td>
            <td
              class="d-none d-lg-block"
              v-html="$t('pricing.treatments.' + treatment + '.description')"
            ></td>
            <td class="text-end">
              <h6>{{ $t("pricing.treatments." + treatment + ".price") }}</h6>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <Footer />
</template>

<script>
import Footer from "@/components/Footer.vue";
import cats from "@/locales/de.json";

export default {
  name: "Pricing",
  data() {
    return {
      treatments: Object.keys(cats.pricing.treatments),
    };
  },
  components: {
    Footer,
  },
};
</script>

<style scoped>
.container-fluid {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width: 992px) {
  .d-lg-block {
    display: table-cell !important;
  }
}

h2 {
  margin-bottom: 30px;
}

td {
  color: #ffffff !important;
}

.text-end {
  white-space: nowrap;
}
</style>
